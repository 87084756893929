<template>
  <div ref="lapsInLead" class="svg-wrapper d-block" v-html="require(`@/assets/svgs/records/04-laps-in-lead.svg`)"></div>
</template>

<script>
import { TimelineLite } from 'gsap'
import { initAnimationObserver } from '@/utils'

export default {
  name: 'LapsInLead',
  props: [
    'svg'
  ],
  data () {
    return {
      played: false,
      timeline: null,
      circles: {
        gold: null,
        red: null,
        white: null
      }
    }
  },
  mounted () {
    const el = this.$refs['lapsInLead']
    initAnimationObserver(el, this.start)

    this.timeline = new TimelineLite()

    setTimeout(() => {
      this.getElements()
    }, 0)
  },
  methods: {
    getElements () {
      this.circles.gold = document.querySelector('#circle-gold') || document.querySelector('wc-laps-in-lead').shadowRoot.querySelector('#circle-gold')
      this.circles.red = document.querySelector('#circle-red') || document.querySelector('wc-laps-in-lead').shadowRoot.querySelector('#circle-red')
      this.circles.white = document.querySelector('#circle-white') || document.querySelector('wc-laps-in-lead').shadowRoot.querySelector('#circle-white')
      this.rotate(this.circles.gold, -90)
      this.rotate(this.circles.red, -90)
      this.rotate(this.circles.white, -90)
    },
    start () {
      var self = this
      var dashArrayLength = self.getCircleLength(self.circles.gold)
      if (!self.played) {
        self.timeline.set(self.circles.gold, { strokeDasharray: dashArrayLength })
        self.timeline.set(self.circles.red, { strokeDasharray: dashArrayLength })
        self.timeline.set(self.circles.white, { strokeDasharray: dashArrayLength })
        self.timeline.fromTo(self.circles.gold, 2.8, { strokeDashoffset: dashArrayLength }, { strokeDashoffset: 60 }, '')
        self.timeline.fromTo(self.circles.red, 2.8, { strokeDashoffset: dashArrayLength }, { strokeDashoffset: 110 }, '')
        self.timeline.fromTo(self.circles.white, 2.8, { strokeDashoffset: dashArrayLength }, { strokeDashoffset: 200 }, '')
        self.played = true
      }
    },
    getCircleLength (el) {
      var r = el.getAttribute('r')
      return 2 * Math.PI * r
    },
    rotate (el, degrees) {
      var cx = el.getAttribute('cx')
      var cy = el.getAttribute('cy')
      el.setAttribute('transform', 'rotate(' + degrees + ', ' + cx + ', ' + cy + ')')
    }
  }
}
</script>
