<template>
  <div ref="grandPrix" class="svg-wrapper d-block" v-html="require(`@/assets/svgs/records/02-grand-prix.svg`)"></div>
</template>

<script>
import { TimelineLite } from 'gsap'
import { initAnimationObserver } from '@/utils'

export default {
  name: 'GrandPrix',
  props: [
    'svg'
  ],
  data () {
    return {
      played: false,
      timeline: null,
      cars: {
        red: null,
        gold: null,
        white: null
      },
      texts: {
        red: null,
        gold: null,
        white: null
      },
      records: {
        a: 0,
        b: 0,
        c: 0
      }
    }
  },
  mounted () {
    const el = this.$refs['grandPrix']
    initAnimationObserver(el, this.start)

    this.timeline = new TimelineLite()

    setTimeout(() => {
      this.getElements()
    }, 0)
  },
  methods: {
    getElements () {
      this.cars.red = document.querySelector('#grand-prix #car-red') || document.querySelector('wc-grand-prix').shadowRoot.querySelector('#grand-prix #car-red')
      this.cars.gold = document.querySelector('#grand-prix #car-gold') || document.querySelector('wc-grand-prix').shadowRoot.querySelector('#grand-prix #car-gold')
      this.cars.white = document.querySelector('#grand-prix #car-white') || document.querySelector('wc-grand-prix').shadowRoot.querySelector('#grand-prix #car-white')
      this.texts.red = document.querySelector('#grand-prix #number-red-gp') || document.querySelector('wc-grand-prix').shadowRoot.querySelector('#grand-prix #number-red-gp')
      this.texts.gold = document.querySelector('#grand-prix #number-gold-gp') || document.querySelector('wc-grand-prix').shadowRoot.querySelector('#grand-prix #number-gold-gp')
      this.texts.white = document.querySelector('#grand-prix #number-white-gp') || document.querySelector('wc-grand-prix').shadowRoot.querySelector('#grand-prix #number-white-gp')
    },
    start () {
      var self = this
      if (!self.played) {
        var cars = self.cars
        var texts = self.texts
        var records = self.records

        // initial state
        self.timeline.to(cars.red, 0, { x: -20, opacity: 0 })
        self.timeline.to(cars.gold, 0, { x: -20, opacity: 0 })
        self.timeline.to(cars.white, 0, { x: -20, opacity: 0 })
        self.timeline.to(records, 0, {
          a: 0,
          b: 0,
          c: 0,
          onUpdate: function () {
            texts.red.textContent = Math.round(records.a)
            texts.gold.textContent = Math.round(records.b)
            texts.white.textContent = Math.round(records.c)
          }
        })

        // animations
        self.timeline.to(cars.red, 1.5, { x: 0, opacity: 1 }, '')
        self.timeline.to(cars.gold, 1.5, { x: 0, opacity: 1 }, '')
        self.timeline.to(cars.white, 1.5, { x: 0, opacity: 1 }, '')
        self.timeline.to(records, 2, {
          a: 91,
          b: 103,
          c: 53,
          onUpdate: function () {
            texts.red.textContent = Math.round(records.a)
            texts.gold.textContent = Math.round(records.b)
            texts.white.textContent = Math.round(records.c)
          }
        })
        self.played = true
      }
    }
  }
}
</script>
