<template>
  <div ref="fastestLaps" class="svg-wrapper d-block" v-html="require(`@/assets/svgs/records/05-fastest-laps.svg`)"></div>
</template>

<script>
import { TimelineLite } from 'gsap'
import { initAnimationObserver } from '@/utils'

export default {
  name: 'FastestLaps',
  props: [
    'svg'
  ],
  data () {
    return {
      played: false,
      timeline: null,
      texts: {
        gold: null,
        red: null,
        white: null
      },
      laps: {
        gold: 0,
        red: 0,
        white: 0
      }
    }
  },
  mounted () {
    const el = this.$refs['fastestLaps']
    initAnimationObserver(el, this.start)

    this.timeline = new TimelineLite()

    setTimeout(() => {
      this.getElements()
    }, 0)
  },
  methods: {
    getElements () {
      this.texts.gold = document.querySelector('#number-gold-laps') || document.querySelector('wc-fastest-laps').shadowRoot.querySelector('#number-gold-laps')
      this.texts.red = document.querySelector('#number-red-laps') || document.querySelector('wc-fastest-laps').shadowRoot.querySelector('#number-red-laps')
      this.texts.white = document.querySelector('#number-white-laps') || document.querySelector('wc-fastest-laps').shadowRoot.querySelector('#number-white-laps')
    },
    start () {
      var self = this
      if (!self.played) {
        // initial state
        self.timeline.to(self.laps, 0, {
          gold: 0,
          red: 0,
          white: 0,
          onUpdate: function () {
            self.texts.gold.textContent = Math.round(self.laps.gold)
            self.texts.red.textContent = Math.round(self.laps.red)
            self.texts.white.textContent = Math.round(self.laps.white)
          }
        })

        // animations
        self.timeline.to(self.laps, 3, {
          gold: 77,
          red: 61,
          white: 46,
          onUpdate: function () {
            self.texts.gold.textContent = Math.round(self.laps.gold)
            self.texts.red.textContent = Math.round(self.laps.red)
            self.texts.white.textContent = Math.round(self.laps.white)
          }
        })
        self.played = true
      }
    }
  }
}
</script>
