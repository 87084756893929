<template>
  <div ref="wmTitles" class="svg-wrapper d-block" v-html="require(`@/assets/svgs/records/01-wm-titles.svg`)"></div>
</template>

<script>
import { TimelineLite } from 'gsap'
import { initAnimationObserver } from '@/utils'

export default {
  name: 'WmTitles',
  props: [
    'svg'
  ],
  data () {
    return {
      played: false,
      timeline: null,
      stars: []
    }
  },
  mounted () {
    const el = this.$refs['wmTitles']
    initAnimationObserver(el, this.start)

    this.timeline = new TimelineLite()

    setTimeout(() => {
      this.getElements()
    }, 0)
  },
  methods: {
    getElements () {
      this.stars.push(document.querySelector('#star-gold-1') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-gold-1'))
      this.stars.push(document.querySelector('#star-gold-2') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-gold-2'))
      this.stars.push(document.querySelector('#star-gold-3') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-gold-3'))
      this.stars.push(document.querySelector('#star-gold-4') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-gold-4'))
      this.stars.push(document.querySelector('#star-gold-5') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-gold-5'))
      this.stars.push(document.querySelector('#star-gold-6') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-gold-6'))
      this.stars.push(document.querySelector('#star-gold-7') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-gold-7'))
      this.stars.push(document.querySelector('#star-red-1') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-red-1'))
      this.stars.push(document.querySelector('#star-red-2') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-red-2'))
      this.stars.push(document.querySelector('#star-red-3') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-red-3'))
      this.stars.push(document.querySelector('#star-red-4') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-red-4'))
      this.stars.push(document.querySelector('#star-red-5') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-red-5'))
      this.stars.push(document.querySelector('#star-red-6') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-red-6'))
      this.stars.push(document.querySelector('#star-red-7') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-red-7'))
      this.stars.push(document.querySelector('#star-white-1') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-white-1'))
      this.stars.push(document.querySelector('#star-white-2') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-white-2'))
      this.stars.push(document.querySelector('#star-white-3') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-white-3'))
      this.stars.push(document.querySelector('#star-white-4') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-white-4'))
      this.stars.push(document.querySelector('#star-white-5') || document.querySelector('wc-wm-titles').shadowRoot.querySelector('#star-white-5'))
    },
    start () {
      var self = this
      if (!self.played) {
        self.stars.forEach(function (star, i) {
          self.timeline.from(star, 1, { x: 2000, opacity: 0, delay: i * 0.1 }, '')
        })
        self.played = true
      }
    }
  }
}
</script>
