<template>
  <div ref="podiumPlaces" class="svg-wrapper d-block" v-html="require(`@/assets/svgs/records/03-podium-places.svg`)"></div>
</template>

<script>
import { TimelineLite } from 'gsap'
import { initAnimationObserver } from '@/utils'

export default {
  name: 'PodiumPlaces',
  props: [
    'svg'
  ],
  data () {
    return {
      played: false,
      timeline: null,
      bars: {
        gold: null,
        red: null,
        white: null
      },
      texts: {
        gold: null,
        red: null,
        white: null
      },
      numbers: {
        gold: 0,
        red: 0,
        white: 0
      }
    }
  },
  mounted () {
    const el = this.$refs['podiumPlaces']
    initAnimationObserver(el, this.start)

    this.timeline = new TimelineLite()

    setTimeout(() => {
      this.getElements()
    }, 0)
  },
  methods: {
    getElements () {
      this.bars.gold = document.querySelector('#bar-gold') || document.querySelector('wc-podium-places').shadowRoot.querySelector('#bar-gold')
      this.bars.red = document.querySelector('#bar-red') || document.querySelector('wc-podium-places').shadowRoot.querySelector('#bar-red')
      this.bars.white = document.querySelector('#bar-white') || document.querySelector('wc-podium-places').shadowRoot.querySelector('#bar-white')
      this.texts.gold = document.querySelector('#number-gold-podium') || document.querySelector('wc-podium-places').shadowRoot.querySelector('#number-gold-podium')
      this.texts.red = document.querySelector('#number-red-podium') || document.querySelector('wc-podium-places').shadowRoot.querySelector('#number-red-podium')
      this.texts.white = document.querySelector('#number-white-podium') || document.querySelector('wc-podium-places').shadowRoot.querySelector('#number-white-podium')
    },
    start () {
      var self = this
      if (!self.played) {
        self.timeline.to(self.numbers, 0, {
          gold: 0,
          red: 0,
          white: 0,
          onUpdate: function () {
            self.texts.gold.textContent = Math.round(self.numbers.gold)
            self.texts.red.textContent = Math.round(self.numbers.red)
            self.texts.white.textContent = Math.round(self.numbers.white)
          }
        })
        self.timeline.to(self.numbers, 3, {
          gold: 191,
          red: 155,
          white: 122,
          onUpdate: function () {
            self.texts.gold.textContent = Math.round(self.numbers.gold)
            self.texts.red.textContent = Math.round(self.numbers.red)
            self.texts.white.textContent = Math.round(self.numbers.white)
          }
        })
        self.timeline.from(self.bars.gold, 3, { scaleY: 0, transformOrigin: '0% 100%' }, '')
        self.timeline.from(self.bars.red, 3, { scaleY: 0, transformOrigin: '0% 100%' }, '')
        self.timeline.from(self.bars.white, 3, { scaleY: 0, transformOrigin: '0% 100%' }, '')

        self.played = true
      }
    }
  }
}
</script>
