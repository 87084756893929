/**
 * Initialize "IntersectionObserver" for animation in "Records" view components
 * @param el
 * @param callback
 */
export const initAnimationObserver = (el, callback) => {
  const observer = new window.IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      callback()
    }
  }, {
    root: null,
    threshold: 0.1
  })

  observer.observe(el)
}
